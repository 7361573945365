import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const latasvizsgalat = () => (
  <Layout>
    <SEO title="Látásvizsgálat" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">




<div className="left2 mt-20 "><h3 className="maintext2 left2 ">A látásjavító eszközöknél (szemüveg, kontaktlencse) legfontosabb az egyedi igények felmérése és a pontos, precíz munka.</h3>
<h3 className="maintext2 mt-2 left2">
Objektív és szubjektív mérésekkel a kívánt távolságra és munka feladatra korrigáljuk ki a látását.</h3>

<h3 className="maintext2 mt-2 left2">
A vizsgálat kb. 20 percet vesz igénybe. Az előző adatait és szemüvegét kérjük hozza magával a vizsgálatra.</h3>













</div></div>

    </div></div>
  </Layout>
)

export default latasvizsgalat
